import jwt_decode from "jwt-decode";
import keycloak from "./utils/keycloak";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { collection, getDocs, addDoc } from "firebase/firestore";

import { db } from "./config/firebaseconfig";
import { Mint } from "./components/mint/Mint";
import CheckmarkAnimation from "./assests/animations/CheckAnimation";

import "./App.css";

function App() {
  var user_mint_data = [];
  const [loading, setLoading] = useState(false);
  const [userMintData, setUserMintData] = useState([]);
  const [checkmarkCardID, setCheckmarkCardID] = useState(null);
  const decoded = jwt_decode(keycloak?.token);
  const userID = decoded?.id 
  // const onbuttonclick = async () => {
  // const userTransactionJson = {
  //   user_id,
  //   name: "CyberPunk Cyborg",
  //   description:
  //     "In a neon-lit future, a cyborg navigates the blurred line between man and machine",
  //   thumbnail_type: ".png",
  //   nft_type: "2D",
  //   price: "100 VANARY",
  //   contract_address: "0xB61aa399dcF3Dd7ACE319Cf85dBD7Df9b4427497",
  //   wallet_address: "",
  //   img_url: "https://i.ibb.co/TqCbGLn/nft1.png",
  //   status: "PENDING",
  //   isMinted: false,
  // };
  // const userTransactionJson2 = {
  //   user_id,
  //   name: "lazy lion",
  //   description:
  //     "A majestic lion, lounging leisurely under the sun, epitomizing the serene power of the wild",
  //   thumbnail_type: ".jpg",
  //   nft_type: "2D",
  //   price: "1000 VANARY",
  //   contract_address: "0xB61aa399dcF3Dd7ACE319Cf85dBD7Df9b4427497",
  //   wallet_address: "",
  //   img_url: "https://i.ibb.co/fMSkP3V/nft2.jpg",
  //   status: "PENDING",
  //   isMinted: false,
  // };
  // const id = "23202";

  // try {
  //   await addDoc(collection(db, "nft_minter"), userTransactionJson);
  // } catch (e) {
  //   console.error("Error adding document: ", e);
  // }
  // };

  const fetchData = async () => {
    setLoading(true);
    try {
      const colRef = collection(db, "nft_minter");
      const querySnapshot = await getDocs(colRef);
      if (querySnapshot.empty) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        querySnapshot.forEach((doc) => {
          if (doc.data().isMinted === false && doc.data().user_id === userID &&doc.data().contract_address) {
            user_mint_data.push({
              id: doc.id,
              ...doc.data(),
            });
            setUserMintData(user_mint_data);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          } else {
            setLoading(false);
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const removeMintedData = (idToRemove) => {
    setTimeout(() => {
      const newMintData = userMintData.filter((data) => data.id !== idToRemove);
      setUserMintData(newMintData);
      setCheckmarkCardID(null);
    }, 2000);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="page-title">VANAR Minting Engine</h1>
        <div className="main-div">
          {/* <button onClick={onbuttonclick}>add to DB</button> */}

          {loading ? (
            <div>
              <Spinner animation="border" role="status" />
            </div>
          ) : userMintData.length > 0 ? (
            userMintData.map((user_data, index) => (
              <>
                {checkmarkCardID === user_data?.id ? (
                  <CheckmarkAnimation />
                ) : (
                  user_data?.isMinted === false && (
                    <div
                      className="card text-left"
                      key={user_data?.id}
                      style={{ marginBottom: "40px" }}
                    >
                      <img
                        className="card-img-top"
                        src={user_data?.img_url}
                        alt="Card pic cap"
                      />
                      <div className="card-body">
                        <p className="card-title">
                          Name:
                          <span className="normal-text">{user_data?.name}</span>
                        </p>
                        <p className="card-title">
                          Description:
                          <span className="normal-text">
                            {user_data?.description}
                          </span>
                        </p>
                        <p className="card-title">
                          Thumbnail Type:
                          <span className="normal-text">
                            {user_data?.thumbnail_type}
                          </span>
                        </p>
                        <p className="card-title">
                          NFT Type:{" "}
                          <span className="normal-text">
                            {user_data?.nft_type}
                          </span>
                        </p>
                        <p className="card-title">
                          Price:{" "}
                          <span className="normal-text">
                            {user_data?.price}
                          </span>
                        </p>
                        {/* {address && localStorage.getItem("isSigned") ? (
                          <Mint
                            id={user_data?.id}
                            removeMintedData={removeMintedData}
                            setCheckmarkCardID={setCheckmarkCardID}
                            checkmarkCardID={checkmarkCardID}
                            isMinted={user_data?.isMinted}
                          />
                        ) : (
                          <button
                            className="btn btn-primary mint-btn"
                            onClick={() => {
                              setIsModalOpen(true);
                            }}
                          >
                            Connect Wallet
                          </button>
                        )} */}
                        <Mint
                          index={index}
                          id={user_data?.id}
                          removeMintedData={removeMintedData}
                          setCheckmarkCardID={setCheckmarkCardID}
                          checkmarkCardID={checkmarkCardID}
                          isMinted={user_data?.isMinted}
                        />
                      </div>
                    </div>
                  )
                )}
              </>
            ))
          ) : (
            <p className="error"> No data found for minting</p>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
