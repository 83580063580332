import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAYvMx6pK1pqeeStmZXirGQV1Qfep8tmOY",
  authDomain: "virtua-binary.firebaseapp.com",
  projectId: "virtua-binary",
  storageBucket: "virtua-binary.appspot.com",
  messagingSenderId: "160013382726",
  appId: "1:160013382726:web:a635164a8242e6e4bf4647",
  measurementId: "G-PQ907BKFRS",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
