/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Image, Spinner } from "react-bootstrap";
import { useConnect, useDisconnect } from "wagmi";
import { useSignMessage, useAccount } from "wagmi";
import {
  createEthereumSiweMessage,
  verifyEthereumMessage,
} from "../../utils/siwe";

import Error from "../modal/Error";
import errorImg from "../../images/error.png";

export function EthWallets({ setIsModalOpen }) {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const { address, connector } = useAccount();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const {
    signMessageAsync,
    isLoading: signing,
    error: signError,
  } = useSignMessage();

  const { disconnect } = useDisconnect();

  const supportedChains = JSON.parse(
    process.env.REACT_APP_ETH_SUPPORTED_CHAINS
  );

  const signMessage = async () => {
    if (isLoading === true || signing === true) {
      setLoading(true);
    } else if (error || signError) {
      setShowError(true);
      disconnect();
      setLoading(false);
    }
    if (address && !error && !signError && !localStorage.getItem("isSigned")) {
      connector?.getChainId().then(async (chainID) => {
        if (!supportedChains.includes(chainID)) {
          setShowError(true);
          setLoading(false);
          disconnect();
        } else {
          setShowError(false);
          const message = createEthereumSiweMessage(
            address,
            "Sign in with Ethereum to Virtua Chain.",
            chainID
          );

          // Convert your message to a byte array
          let byteArray = new Uint8Array(message.length);
          for (let i = 0; i < message.length; i++) {
            byteArray[i] = message.charCodeAt(i);
          }

          // Encode the byte array using Base64
          let base64Encoded = btoa(String.fromCharCode.apply(null, byteArray));
          let verified = false;
          try {
            const signature = await signMessageAsync({ message });
            localStorage.setItem("wagmi.walletSignature", signature);
            localStorage.setItem("wagmi.walletMessage", base64Encoded);

            verified = await verifyEthereumMessage(message, signature);
            if (verified) {
              setIsModalOpen(false);
              setLoading(false);
              localStorage.setItem("isSigned", true);
            } else {
              localStorage.removeItem("isSigned");
            }
          } catch (error) {
            localStorage.removeItem("isSigned");
            localStorage.removeItem("wagmi.walletAddress");
          }
        }
      });
    }
  };
  const handleButtonClick = async (connector) => {
    if (!connector.ready) {
      window.open("https://metamask.io/download/", "_blank");
    } else {
      setLoading(true);
      connect({ connector });
    }
  };

  useEffect(() => {
    signMessage();
  }, [address, error, signError]);

  return (
    <>
      <p className="text">Please Select Ethereum wallet to start minting</p>
      <ul className="d-flex p-2">
        {connectors.map((connector, index) => (
          <li key={`eth${index + 1}`}>
            <button
              key={connector?.id}
              onClick={() => handleButtonClick(connector)}
              className="custom-btn lg dim-border w-100"
            >
              {connector?.name === "MetaMask" ? (
                <Image
                  className="wallet-img"
                  src={process.env.REACT_APP_S3_IMAGE_PATH + "metamaskBtn.png"}
                  alt="metamask"
                />
              ) : connector?.name === "WalletConnect" ? (
                <Image
                  className="wallet-img"
                  src={process.env.REACT_APP_S3_IMAGE_PATH + "connectBtn.png"}
                  alt="walletconnect"
                />
              ) : (
                <Image
                  className="wallet-img "
                  src={process.env.REACT_APP_S3_IMAGE_PATH + "coinbaseBtn.png"}
                  alt="coinbasewallet"
                />
              )}
              <span className="txt">
                {loading && connector.id === pendingConnector.id ? (
                  <div className="spinner">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        color: "black",
                      }}
                      variant="secondary"
                    />
                  </div>
                ) : (
                  `${connector.name}`
                )}
              </span>
            </button>
          </li>
        ))}
      </ul>
      <Error
        isOpen={showError}
        onClose={() => setShowError(false)}
        error={signError && "SignError"}
        setIsModalOpen={setIsModalOpen}
      >
        <Image className="error-img" src={errorImg} alt="Error" />
        {error && (
          <p className="error-message text">User denied authorization</p>
        )}
        {signError && (
          <p className="error-message text">
            You did not provide a signature for this transaction.
          </p>
        )}
      </Error>
    </>
  );
}
