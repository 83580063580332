import { createConfig, configureChains } from "wagmi";
import { mainnet, polygonMumbai, goerli, sepolia } from "wagmi/chains";

import { publicProvider } from "wagmi/providers/public";

import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { infuraProvider } from "wagmi/providers/infura";

const supportedChains = [];
const envChains = JSON.parse(process.env.REACT_APP_ETH_SUPPORTED_CHAINS || [1]);

if (envChains?.includes(1)) supportedChains.push(mainnet);
if (envChains?.includes(5)) supportedChains.push(goerli);
if (envChains?.includes(80001)) supportedChains.push(polygonMumbai);
if (envChains?.includes(11155111)) supportedChains.push(sepolia);
const { chains, publicClient, webSocketPublicClient } = configureChains(
  supportedChains,
  [
    infuraProvider({ apiKey: process.env.REACT_APP_INFURA_KEY }),
    publicProvider(),
  ]
);

export const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: "4719d3708f4c5626f9717ce01b88ff71",
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "Virtua Chain",
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});
