import { useEffect, useState } from "react";
import {
  useAccount,
  useContractWrite,
  useWaitForTransaction,
  usePrepareContractWrite,
} from "wagmi";
import { Image, Spinner } from "react-bootstrap";
import { doc, updateDoc } from "firebase/firestore";

import { db } from "../../config/firebaseconfig";
import { EthWallets } from "../wallets/ethWallets";
import Modal from "../modal/Modal";

import "../../App.css";
import Error from "../modal/Error";
import errorImg from "../../images/error.png";

export function Mint({ id, removeMintedData, setCheckmarkCardID }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [initial_card, setInitialCard] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMintBtnClicked, setIsMintBtnClicked] = useState(false);

  const { address } = useAccount();

  const { config } = usePrepareContractWrite({
    address,
    abi: [
      {
        name: "mintTo",
        type: "function",
        stateMutability: "nonpayable",
        inputs: [],
        outputs: [],
      },
    ],
    functionName: "mintTo",
  });

  const { data, write, isError: MintRejected } = useContractWrite(config);

  const { isLoading: transactionLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  const updateStatus = async (status, isMinted, wallet_address) => {
    const userDoc = doc(db, "nft_minter", id);
    await updateDoc(userDoc, {
      isMinted,
      status,
      wallet_address,
    }).then(() => {
      if (status === "SUCCESS") {
        setCheckmarkCardID(id);
        removeMintedData(id);
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      updateStatus("SUCCESS", true, address);
    }
    if (MintRejected) {
      setIsLoading(false);
      setIsMintBtnClicked(false);
      setShowError(true);
    }
    if (transactionLoading) {
      updateStatus("PROCESSING", false, address);
      setIsLoading(true);
      setIsMintBtnClicked(false);
    }
  }, [isSuccess, transactionLoading, MintRejected]);

  useEffect(() => {
    if (address && localStorage.getItem("isSigned") && initial_card === id) {
      setIsMintBtnClicked(true);
      write();
    }
  }, [address, localStorage.getItem("isSigned"), initial_card, id]);

  return (
    <div>
      {address && localStorage.getItem("isSigned") ? (
        <button
          className="btn btn-primary mint-btn"
          disabled={!write || isLoading || isMintBtnClicked}
          onClick={() => {
            setIsMintBtnClicked(true);
            write();
          }}
        >
          {isLoading ? (
            "Minting..."
          ) : isMintBtnClicked ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Mint"
          )}
        </button>
      ) : (
        <button
          className="btn btn-primary mint-btn"
          onClick={() => {
            setIsModalOpen(true);
            setInitialCard(id);
          }}
        >
          Mint
        </button>
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <EthWallets setIsModalOpen={setIsModalOpen} />
      </Modal>
      <Error
        isOpen={showError}
        onClose={() => setShowError(false)}
        error={MintRejected && "Minting Error"}
        setIsModalOpen={setIsModalOpen}
      >
        <Image className="error-img" src={errorImg} alt="Error" />
        {MintRejected && (
          <p className="error-message text">Minting rejected by user</p>
        )}
      </Error>
    </div>
  );
}
