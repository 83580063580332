import React from "react";

const Error = ({ isOpen, onClose, children, error, setIsModalOpen }) => {
  if (!isOpen) return null;

  const closeModal = () => {
    if (error) {
      setIsModalOpen(false);
      onClose();
    } else {
      onClose();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={closeModal} className="close">
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default Error;
