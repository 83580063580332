import { SiweMessage } from "siwe";

const domain = window.location.host;
const origin = window.location.origin;

function createNonce(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function createEthereumSiweMessage(address, statement, chainId) {
  const nonce = createNonce(20);
  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: "1",
    chainId,
    nonce,
  });
  localStorage.setItem("wagmi.walletAddress", address);
  localStorage.setItem("wagmi.nonce", nonce);
  localStorage.setItem("wagmi.chainId", chainId?.toString());
  return message.prepareMessage();
}

export function createCardanoSiweMessage(address, statement, chainId) {
  const nonce = createNonce(20);
  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: "1",
    chainId,
    nonce,
  });
  localStorage.setItem("cardano.walletAddress", address);
  localStorage.setItem("cardano.chainId", chainId?.toString());
  return message.prepareMessage();
}

export async function signOutCardano() {
  localStorage.removeItem("cardano.nonce");
  localStorage.removeItem("cardano.walletAddress");
  localStorage.removeItem("cardano.chainId");

  return true;
}

export async function verifyEthereumMessage(message, signature) {
  let msg = new SiweMessage(message);
  let result = await msg.validate(signature);
  const verifyResult =
    result.nonce === localStorage.getItem("wagmi.nonce") &&
    result.address === localStorage.getItem("wagmi.walletAddress");
  if (verifyResult) {
    localStorage.removeItem("wagmi.nonce");
  }
  return verifyResult;
}

export async function verifyCardanoMessage(message, signature) {
  let msg = new SiweMessage(message);
  let result = await msg.validate(signature);
  const verifyResult =
    result.nonce === localStorage.getItem("cardano.nonce") &&
    result.address === localStorage.getItem("cardano.walletAddress");
  if (verifyResult) localStorage.removeItem("cardano.nonce");
  return verifyResult;
}
