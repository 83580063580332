import React from "react";
import Lottie from "react-lottie";
import animationData from "../lottieJSON/checkMark.json";

function CheckmarkAnimation() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Lottie options={defaultOptions} height={478} width={478} />
      <h3 className="success">NFT Successfully Minted</h3>
    </>
  );
}

export default CheckmarkAnimation;
