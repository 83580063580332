import Keycloak from "keycloak-js";

const keycloakConfig = {
  url: "https://accounts.bimtvi.com/",
  realm: "virtua",
  clientId: "minting-engine",
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
