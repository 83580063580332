import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { WagmiConfig } from "wagmi";
import { config } from "./config/index";
import keycloak from "./utils/keycloak";
import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

keycloak
  .init({ onLoad: "login-required" })
  .then((authenticated) => {
    if (authenticated) {
      // User is authenticated
      root.render(
        <WagmiConfig config={config}>
          <App />
        </WagmiConfig>
      );
    } else {
      // Not authenticated or other errors
      console.error("Authenticated failed");
    }
  })
  .catch((error) => {
    console.error("Keycloak init error", error);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
